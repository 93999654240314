<script setup>
import { DateTime } from 'luxon';
const props = defineProps({
  /** @type {import('vue').PropOptions<Post>} */
  expirationMillis: {
    type: Number,
    default: 0,
  },
});

let currentTime = DateTime.now();

const interval = setInterval(() => {
  currentTime = DateTime.now();
}, 1000);

// Computed

const showRelativeTime = computed(() => {
  const expirationTime = DateTime.fromMillis(props.expirationMillis);
  const diff = expirationTime.diff(currentTime);
  return diff.as('hours') > 2;
});
const relativeTime = computed(() => {
  const expirationTime = DateTime.fromMillis(props.expirationMillis);
  return expirationTime.toRelative();
});
const countdown = computed(() => {
  const expirationTime = DateTime.fromMillis(props.expirationMillis);
  const diff = expirationTime.diff(currentTime);
  const hours = Math.floor(diff.as('hours'));
  const minutes = Math.floor(diff.as('minutes') % 60);
  const seconds = Math.floor(diff.as('seconds') % 60);
  return `${hours}h ${minutes}m ${seconds}s`;
});

onBeforeUnmount(() => {
  clearInterval(interval);
});
</script>

<template>
  <div class="px-1">
    <span v-if="showRelativeTime">{{ relativeTime }}</span>
    <span v-else>{{ countdown }}</span>
  </div>
</template>
